import React from "react";
import styled from "styled-components";
import ManPNG from "../assets/man.png";
import { useHistory } from "react-router-dom";
import { part as partApi } from "../api";
import Loader from "react-loading";
import { tugrug } from "../utils";
import qs from "qs";
import Toggler from "../components/Toggler";

export default () => {
  const history = useHistory();
  const [{ makers, markets, models, bodies, years }, setSelection] =
    React.useState({
      makers: [],
      markets: [],
      models: [],
      bodies: [],
      years: [],
    });
  const [loading, setLoading] = React.useState();
  const [{ type, payload }, setResult] = React.useState({
    type: "PRODUCT",
    payload: [],
  });
  const [offset, setOffset] = React.useState({ page: 1, limit: 10 });
  const [position, setPosition] = React.useState({});
  const [faded, setFaded] = React.useState();
  const [filters, setFilters] = React.useState({
    maker_id: null,
    market_id: null,
    model_id: null,
    body_id: null,
    year: null,
  });

  const onPosition = (pos) => {
    setPosition(pos);
    setFaded(true);

    setTimeout(() => {
      setFaded(false);
    }, 100);
  };

  const onSearch = async (filter) => {
    try {
      setLoading(true);
      let res = await partApi.search({
        filter: filter,
        offset,
      });

      setResult(res);
      setLoading(false);

      onPosition(
        res?.payload?.body_positions && res?.payload?.body_positions[0]
      );
    } catch (err) {
      console.log(err);
      setResult({ type: "PRODUCT", payload: [] });
      setLoading(false);

      throw err;
    }
  };

  const onChange = async (type, value) => {
    if (!value || value === "-") return onClear(type);

    let change = {
      ...filters,
      [type]: value,
    };

    switch (type) {
      case "maker_id": {
        change = {
          ...change,
          market_id: null,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "market_id": {
        change = {
          ...change,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "model_id": {
        change = {
          ...change,
          body_id: null,
          year: null,
        };
        break;
      }
      case "body_id": {
        change = {
          ...change,
          year: null,
        };
        break;
      }
      default:
    }

    setFilters(change);
    setLoading(true);

    let search = qs.stringify(change);

    history.push({
      search: search,
    });

    let res = await partApi.query(type, value, { maker_id: filters.maker_id });
    let selection = { makers, markets, models, bodies, years };

    switch (type) {
      case "maker_id": {
        selection = { makers, markets: [], models: [], bodies: [], years: [] };
        break;
      }
      case "market_id": {
        selection = { makers, markets, models: [], bodies: [], years: [] };
        break;
      }
      case "model_id": {
        selection = { makers, markets, models, bodies: [], years: [] };
        break;
      }
      case "body_id": {
        selection = { makers, markets, models, bodies, years: [] };
        break;
      }
      default:
    }

    setSelection({ ...selection, ...res });

    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const onClear = (type) => {
    let change = {
      [type]: null,
    };

    switch (type) {
      case "maker_id": {
        change = {
          ...change,
          market_id: null,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "market_id": {
        change = {
          ...change,
          model_id: null,
          body_id: null,
          year: null,
        };
        break;
      }
      case "model_id": {
        change = {
          ...change,
          body_id: null,
          year: null,
        };
        break;
      }
      case "body_id": {
        change = {
          ...change,
          year: null,
        };

        onChange("model_id", filters.model_id);
        break;
      }
      case "year": {
        change = {
          ...change,
          body_id: null,
        };
        break;
      }
      default:
    }

    setFilters((state) => ({ ...state, ...change }));
  };

  const onLoading = () => {
    return (
      <LoaderContainer>
        <Loader type="cylon" color="#2b4159" />
      </LoaderContainer>
    );
  };

  const loadData = async (params) => {
    if (
      params.maker_id &&
      params.market_id &&
      params.model_id &&
      params.body_id
    ) {
      setFilters(params);

      let res = {},
        selection = { makers, markets, models, bodies, years };

      res = await partApi.query("search", 1, { maker_id: params.maker_id });
      selection = { ...selection, ...res };

      res = await partApi.query("maker_id", params.maker_id, {
        maker_id: params.maker_id,
      });
      selection = { ...selection, ...res };

      res = await partApi.query("market_id", params.market_id, {
        maker_id: params.maker_id,
      });
      selection = { ...selection, ...res };

      res = await partApi.query("model_id", params.model_id, {
        maker_id: params.maker_id,
      });
      selection = { ...selection, ...res };

      res = await partApi.query("body_id", params.body_id, {
        maker_id: params.maker_id,
      });
      selection = { ...selection, ...res };

      setSelection(selection);
      onSearch(params);
    } else {
      onChange("search", 1);
    }
  };

  React.useEffect(() => {
    let params = qs.parse(history.location.search?.replace("?", ""));

    loadData(params);
  }, []);

  function checkProductStatus(quantity) {
    if (quantity === 0) {
      return <div class="badge out-of-stock">дууссан</div>;
    }
    if (quantity < 3) {
      return <div class="badge">цөөн тоотой</div>;
    }
    return <div class="badge ready">бэлэн</div>;
  }

  return (
    <Container className="container">
      <div className="section">
        <div className="row">
          <div className="col-sm-10">
            <h2>Автомашины бренд сэлбэг</h2>
            <p>Та өөрийн авто машины чанартай сэлбэгийг биднээс аваарай.</p>
            <Toggler />
            <div className="row gy-2">
              <div className="col-xs-12 col-sm-4 part-select">
                <select
                  className="form-control form-control-md"
                  value={filters.maker_id || ""}
                  onChange={(e) => onChange("maker_id", e.target.value)}
                  placeholder="Үйлдвэрлэгч"
                  disabled={makers.length === 0}
                >
                  <option>Үйлдвэрлэгч</option>
                  {makers
                    ?.sort((a, b) => a.name - b.name)
                    ?.map((maker, index) => (
                      <option value={maker.id} key={index}>
                        {maker.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-4 part-select">
                <select
                  className="form-control form-control-md"
                  value={filters.market_id || ""}
                  onChange={(e) => onChange("market_id", e.target.value)}
                  placeholder="Маркет"
                  disabled={markets.length === 0}
                >
                  <option>Маркет</option>
                  {markets
                    ?.sort((a, b) => a.name - b.name)
                    ?.map((market, index) => (
                      <option value={market.id} key={index}>
                        {market.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-4 part-select">
                <select
                  className="form-control form-control-md"
                  value={filters.model_id || ""}
                  onChange={(e) => onChange("model_id", e.target.value)}
                  placeholder="Модел"
                  disabled={models.length === 0}
                >
                  <option>Модел</option>
                  {models
                    ?.sort((a, b) => a.code - b.code)
                    ?.map((model, index) => (
                      <option value={model.id} key={index}>
                        {model.code}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-4 part-select">
                <select
                  className="form-control form-control-md"
                  value={filters.body_id || ""}
                  onChange={(e) => onChange("body_id", e.target.value)}
                  placeholder="Араам (body)"
                  disabled={bodies.length === 0}
                >
                  <option>Араам (body)</option>
                  {bodies
                    ?.sort((a, b) => a.name - b.name)
                    ?.filter((b) => {
                      if (!filters.year || filters.year === "-") return true;

                      let start = parseInt(b?.start_date?.split(".")[0], 10);
                      let end = parseInt(
                        b?.end_date?.split(".")[0] ||
                          `${new Date().getFullYear()}`,
                        10
                      );
                      let year = parseInt(filters.year, 10);

                      if (start <= year && year <= end) return true;

                      return false;
                    })
                    .map((body, index) => (
                      <option value={body.id} key={index}>
                        {body.code}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-4 part-select">
                <select
                  className="form-control form-control-md"
                  value={filters.year || ""}
                  onChange={(e) => onChange("year", e.target.value)}
                  placeholder="Огноо"
                  disabled={years.length === 0}
                >
                  <option value="-">Огноо</option>
                  {years
                    .sort((a, b) => b - a)
                    .map((year, index) => (
                      <option value={year} key={index}>
                        {year}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-4 part-select">
                <button
                  className="btn btn-primary btn-md btn-block"
                  type="button"
                  disabled={!filters.body_id}
                  onClick={() => onSearch(filters)}
                >
                  Хайх
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div>

        <img className="man" src={ManPNG} />

        {loading && onLoading()}
      </div>

      {(({ type, data, s3host }) => {
        const onCar = (note) => {
          let array = note
            ?.split("on car:")[1]
            ?.split("&&")[0]
            ?.trim()
            ?.split("package:");

          let car = ((array && array[0]) || "")?.trim();
          let pcs = ((array && array[1]) || "")?.trim();

          return (
            <>
              <div style={{ color: "#f50", fontSize: 12, fontWeight: 500 }}>
                On car: {car}{" "}
              </div>
              <div color="#2db7f5" style={{ fontSize: 12, fontWeight: 500 }}>
                Package: {pcs}{" "}
              </div>
            </>
          );
        };

        switch (type) {
          case "PART": {
            return (
              <div className="part">
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                    <div className="body">
                      <h2>
                        {data?.maker?.name} {data?.model?.name}{" "}
                        <small>{data?.market?.name}</small>
                      </h2>
                      <div className="field">
                        <label>Дугаар (body number):</label>
                        <div className="value">{data?.code}</div>
                      </div>
                      <div className="field">
                        <label>Үйлдвэрлэсэн огноо:</label>
                        <div className="value">
                          {data?.start_date}-{data?.end_date}
                        </div>
                      </div>
                    </div>

                    <div className="schema">
                      <img
                        className="fade-in"
                        src={`${s3host || ""}/${position.image}`}
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-8">
                    <ul className="tabs">
                      {data?.body_positions
                        ?.sort((a, b) => a.code - b.code)
                        ?.map((pos, index) => {
                          return (
                            <li
                              id={pos.code}
                              key={index}
                              className={`${
                                position?.id === pos.id && "active"
                              }`}
                            >
                              <a href onClick={() => onPosition(pos)}>
                                <img src={`${s3host}/${pos.icon}`} />
                                {pos.name}
                              </a>
                            </li>
                          );
                        })}
                    </ul>

                    {!faded && (
                      <div className="fade-in">
                        <table className="table table-part">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Part</th>
                              <th scope="col">Үнэ(ш)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.parts
                              ?.filter(
                                (part) => part.body_position_id === position.id
                              )
                              .sort(
                                (a, b) =>
                                  a.sort_of_position - b.sort_of_position
                              )
                              .map((part, index) => {
                                if (!part?.part)
                                  return (
                                    <tr key={index}>
                                      <td scope="row" className="on-schema">
                                        {part.sort_of_position}
                                      </td>
                                      <td style={{ fontSize: 14 }}>
                                        Not Available
                                        {part.additional && (
                                          <div style={{ fontSize: 12 }}>
                                            {part.additional}
                                          </div>
                                        )}
                                      </td>
                                      <td className="part-price">-</td>
                                    </tr>
                                  );

                                return (
                                  <tr key={index}>
                                    <td scope="row" className="on-schema">
                                      {part.sort_of_position}
                                      <img
                                        src={
                                          part?.image ||
                                          `${s3host}/${part?.part?.image}`
                                        }
                                      />
                                    </td>
                                    <td>
                                      <div className="part-info">
                                        <div className="part-no">
                                          {part?.part?.code}
                                        </div>
                                        {part?.part?.name}
                                        {part.additional && (
                                          <div style={{ fontSize: 12 }}>
                                            {part.additional}
                                          </div>
                                        )}
                                        {part.note && onCar(part.note)}
                                      </div>
                                    </td>
                                    <td>
                                      {tugrug(part?.part?.product?.price)}
                                      <div>
                                        {checkProductStatus(
                                          part?.part?.product?.quantity
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {data?.parts?.filter(
                      (part) => part.body_position_id === position.id
                    ).length === 0 && (
                      <h4 className="notfound">Мэдээлэл байхгүй!</h4>
                    )}
                  </div>
                </div>
              </div>
            );
          }
          default:
        }
      })({ type, data: payload, s3host: payload?.s3host })}
    </Container>
  );
};
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  z-index: 1000;
  & > div {
    margin: auto;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 60px auto;
  h2 {
    font-size: 28px;
  }
  p {
    font-weight: 600;
    font-size: 17px;
    color: #333;
  }
  .btn-primary {
    background-color: #369cd9;
    border-color: #169fe8;
    &:hover {
      background-color: #369cd9;
      border-color: #169fe8;
    }
    &.btn-block {
      width: 100%;
    }
  }
  .section {
    position: relative;
    background: #f4f6fa;
    padding: 24px 48px;
    border-radius: 10px;
    .col-sm-4 {
      padding-left: 10px;
      padding-right: 10px;
    }

    .part-select {
      z-index: 10;
    }

    .man {
      position: absolute;
      right: -10px;
      bottom: 0;
      height: 280px;
    }
    @media only screen and (max-width: 500px) {
      .man {
        top: 0;
        right: -20px;
        bottom: inherit;
        height: 150px;
      }
    }
  }
  .part {
    margin: 20px 0;
    padding: 8px;

    .notfound {
      text-align: center;
      font-size: 15px;
      color: #333;
      padding: 20px;
    }
    .body {
      h2 {
        font-size: 22px;
      }
      .field {
        margin-bottom: 10px;
        label {
          font-size: 15px;
          margin-bottom: 0;
          font-weight: 500;
        }
        .value {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
  .table-part {
    thead {
      th {
        border: none !important;
        font-size: 12px;
        padding: 5px 0.75rem;
      }
    }

    .on-schema {
      width: 120px;
      img {
        width: 100%;
      }
    }
    .part-info {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      .part-no {
        min-width: 90px;
        text-align: center;
        margin-right: 5px;
        background: #169ee6;
        color: #fff;
        font-weight: bold;
        padding: 2px 4px;
        border-radius: 4px;
      }
    }
    .part-price {
      font-size: 15px;
      width: 130px;
    }
    .part-attributes {
      display: block;
      margin-top: 10px;
      div {
        margin-right: 5px;
        color: #333;
        font-weight: 500;
        padding: 2px 4px;
        border-radius: 4px;
        border: 1px solid #dee2e6;
        font-size: 12px;
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }

  .tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    @media only screen and (max-width: 500px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      display: inline-block;
      margin-right: 4px;
      margin-bottom: 4px;
      border: 2px solid #efefef;
      border-radius: 10px;
      a {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 4px 8px;
        border-bottom: 4px solid transparent;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #545454;
        text-decoration: none;
        transition: background 0.2s linear;
        cursor: pointer;
        text-align: center;
        img {
          width: 100px;
          margin: auto;
        }
        &:hover {
          background: #f4f6fa;
        }
      }
      &.active {
        border: none;
        a {
          border: 2px solid #169ee6;
          border-bottom: 4px solid #169ee6;
          color: #169ee6;
        }
      }
    }
  }
  .schema {
    img {
      width: 100%;
    }
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .badge {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    color: #fff;
  }

  /* Colors for different statuses */
  .badge.ready {
    background-color: #4caf50; /* Green */
  }

  .badge.out-of-stock {
    background-color: #f44336; /* Red */
  }

  .badge {
    background-color: #ff9800; /* Orange */
  }
`;
