import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

const Toggler = () => {
  const [selectedPage, setSelectedPage] = useState("home");
  const history = useHistory();
  const location = useLocation();

  const handlePageChange = (page) => {
    setSelectedPage(page);
    history.push(page === "home" ? "/" : "/kn");
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedPage("home");
    } else if (location.pathname.startsWith("/kn")) {
      setSelectedPage("kn");
    }
  }, [location]);

  return (
    <TabContainer className="nav nav-tabs mb-4">
      <li className="nav-item">
        <a
          className={`nav-link ${selectedPage === "home" ? "active" : ""}`}
          onClick={() => handlePageChange("home")}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/tochka.svg"
            style={{ height: 40 }}
            alt="tochka parts"
            draggable={false}
          />
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link ${selectedPage === "kn" ? "active" : ""}`}
          onClick={() => handlePageChange("kn")}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/kn.svg"
            style={{ height: 40 }}
            alt="kn parts"
            draggable={false}
          />
        </a>
      </li>
    </TabContainer>
  );
};

export default Toggler;

const TabContainer = styled.ul`
  border-bottom: 1px solid #369cd9;

  .nav-link {
    &.active {
      background: transparent;
      border-color: #369cd9 #369cd9 #f4f6fa;
    }

    &:hover {
      border-color: #369cd9 #369cd9 #f4f6fa;
    }
  }
`;
