/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import Loader from "react-loading";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { kn as knPartApi } from "../api";
import ManPNG from "../assets/man.png";
import Toggler from "../components/Toggler";
import { tugrug } from "../utils";

export default () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState();
  const [filter, setFilter] = React.useState({
    maker: null,
    model: null,
    year: null,
    engine: null,
    query: "",
    makers: [],
    models: [],
    engines: [],
  });

  useEffect(() => {
    async function fetchMakers() {
      try {
        setLoading(true);
        const response = await knPartApi.filter(filter.year, "maker", "0");
        setFilter((prev) => ({ ...prev, makers: response }));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching makers:", error);
      }
    }

    if (filter.year) {
      fetchMakers();
    }
  }, [filter.year]);

  // Updated handleChange function
  const handleChange = (field) => (e) => {
    const value = e.target.value;

    let selectedObject = null;
    if (field === "maker") {
      selectedObject = filter.makers.find((maker) => maker.id === value);
    } else if (field === "model") {
      selectedObject = filter.models.find((model) => model.id === value);
    } else if (field === "engine") {
      selectedObject = filter.engines.find((engine) => engine.id === value);
    } else if (field === "year") {
      selectedObject = value;
    } else if (field === "query") {
      selectedObject = value;
    }

    setFilter((prev) => ({ ...prev, [field]: selectedObject }));
  };

  // Updated handleSearch function
  const handleSearch = async () => {
    const { year, maker, model, engine, query } = filter;
    const payload = {
      filter: {
        query,
        year,
        maker: maker?.maker, // Only passing ID
        model: model?.model, // Only passing ID
        engine: engine?.engine, // Only passing ID
      },
      offset: {
        page: "1",
        limit: "20",
      },
    };

    try {
      const response = await knPartApi.search(payload);
      setFilter((prev) => ({ ...prev, results: response }));
    } catch (error) {
      console.error("Error searching parts:", error);
    }
  };

  // Fetching models and engines with full objects
  useEffect(() => {
    async function fetchModels() {
      if (filter.year && filter.maker) {
        try {
          setLoading(true);
          const response = await knPartApi.filter(
            filter.year,
            "model",
            filter.maker.id
          );
          setFilter((prev) => ({ ...prev, models: response }));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      }
    }
    fetchModels();
  }, [filter.year, filter.maker]);

  useEffect(() => {
    async function fetchEngines() {
      if (filter.year && filter.model) {
        try {
          setLoading(true);
          const response = await knPartApi.filter(
            filter.year,
            "engine",
            filter.model.id
          );
          setFilter((prev) => ({ ...prev, engines: response }));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching engines:", error);
        }
      }
    }
    fetchEngines();
  }, [filter.year, filter.model]);

  const onLoading = () => {
    return (
      <LoaderContainer>
        <Loader type="cylon" color="#2b4159" />
      </LoaderContainer>
    );
  };

  return (
    <Container className="container">
      <div className="section">
        <div className="row position-relative" style={{ zIndex: 10 }}>
          <div className="col-sm-10">
            <h2>Автомашины бренд сэлбэг</h2>
            <p>Та өөрийн авто машины чанартай сэлбэгийг биднээс аваарай.</p>
            <Toggler />
            <div className="row gy-2">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <input
                  className="form-control"
                  placeholder="Кодоор хайх"
                  value={filter.query}
                  onChange={handleChange("query")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <select
                  value={filter.year}
                  onChange={handleChange("year")}
                  className="form-select"
                >
                  <option value="">Он сонгоно уу</option>
                  {[...Array(50).keys()].map((y) => (
                    <option key={y} value={new Date().getFullYear() - y}>
                      {new Date().getFullYear() - y}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <select
                  value={filter.maker ? filter.maker.id : ""} // Ensure you handle the selected maker correctly
                  onChange={handleChange("maker")}
                  disabled={!filter.makers.length}
                  className="form-select"
                >
                  <option value="">Үйлдвэрлэгч сонгоно уу</option>
                  {filter.makers?.map((maker) => (
                    <option key={maker.id} value={maker.id}>
                      {maker.maker}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <select
                  value={filter.model ? filter.model.id : ""}
                  onChange={handleChange("model")}
                  disabled={!filter.models.length}
                  className="form-select"
                >
                  <option value="">Загвараа сонгоно уу</option>
                  {filter.models?.map((model) => (
                    <option key={model.id} value={model.id}>
                      {model.model}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <select
                  value={filter.engine ? filter.engine.id : ""}
                  onChange={handleChange("engine")}
                  disabled={!filter.engines.length}
                  className="form-select"
                >
                  <option value="">Хөдөлгүүрээ сонгоно уу</option>
                  {filter.engines.map((engine) => (
                    <option key={engine.id} value={engine.id}>
                      {engine.engine}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <button
                  onClick={() => handleSearch()}
                  className="btn btn-primary btn-block"
                >
                  Хайх
                </button>
              </div>
            </div>
          </div>
        </div>

        <img className="man" src={ManPNG} />

        {loading && onLoading()}
      </div>
      <div className="row my-4">
        {filter?.results?.payload?.products?.length ? (
          filter.results.payload.products.map((product) => (
            <div key={product.id} className="col-xs-12 col-sm-6 col-md-4">
              <div
                className="wrapper p-3 rounded"
                onClick={() => history.push(`/kn/${product?.knPart?.id}`)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                  className="mb-3"
                >
                  <h4
                    className="badge bg-primary"
                    style={{ background: "#369cd9 !important" }}
                  >
                    {product.code ?? "-"}
                  </h4>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Үнэ:</span>
                      <strong>{tugrug(product.price ?? 0)}</strong>
                    </div>
                    <span
                      className={`badge ${
                        product.quantity === 0
                          ? "bg-danger"
                          : product.quantity <= 3
                          ? "bg-warning"
                          : "bg-success"
                      }`}
                    >
                      {product.quantity === 0
                        ? "Дууссан"
                        : product.quantity <= 3
                        ? "Цөөн"
                        : "Бэлэн"}
                    </span>
                  </div>
                </div>
                <div className="image mb-3">
                  <img
                    src={`${filter?.results?.payload?.s3host}${
                      product?.image?.startsWith("/")
                        ? product.image
                        : `/${product.image}`
                    }`}
                    style={{ width: "100%", height: 160, objectFit: "contain" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/noimage.jpg";
                    }}
                    draggable={false}
                  />
                </div>
                <div className="fs-5 mb-1">{product.name ?? "-"}</div>
                <div className="fs-6 text-muted mb-1">
                  {product.description}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center w-100 my-4">
            <h4>Өгөгдөл олдсонгүй</h4>
            <p>Та сонирхож буй сэлбэгээ хайлт болон шүүлт хийж олно уу.</p>
          </div>
        )}
      </div>
    </Container>
  );
};
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  z-index: 1000;
  & > div {
    margin: auto;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 60px auto;

  h2 {
    font-size: 28px;
  }

  p {
    font-weight: 600;
    font-size: 17px;
    color: #333;
  }

  .btn-primary {
    background-color: #369cd9;
    border-color: #169fe8;
    &:hover {
      background-color: #369cd9;
      border-color: #169fe8;
    }
    &.btn-block {
      width: 100%;
    }
  }

  .section {
    position: relative;
    background: #f4f6fa;
    padding: 24px 48px;
    border-radius: 10px;

    .col-sm-4 {
      padding-left: 10px;
      padding-right: 10px;
    }

    .part-select {
      margin-bottom: 10px;
      z-index: 10;
    }

    .man {
      position: absolute;
      right: -10px;
      bottom: 0;
      height: 280px;
    }

    @media only screen and (max-width: 500px) {
      .man {
        top: 0;
        right: -20px;
        bottom: inherit;
        height: 150px;
      }
    }
  }

  .wrapper {
    transition: box-shadow 0.3s ease-in-out;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      border: 1px solid #f4ebeb;
    }
  }
`;
